/*

import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;

*/

import React, { useEffect, useState } from "react";
import './App.css';

import Box from '@mui/material/Box';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';

import Container from '@mui/material/Container';

import axios from "axios";

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Switch2 from '@mui/material/Switch';

import CoinChimpLogo from './images/coinchimp.svg';


import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";


import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';


// Menu

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import HomeIcon from '@mui/icons-material/Home';
import PetsIcon from '@mui/icons-material/Pets';
import Divider from '@mui/material/Divider';

import { useHistory, useLocation } from "react-router-dom";

import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';


// Tab Menu

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import HighlightAltIcon from '@mui/icons-material/HighlightAlt';

import TimeAgo from 'javascript-time-ago';

import en from 'javascript-time-ago/locale/en.json'

TimeAgo.addDefaultLocale(en)



const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'rgba(255, 255, 255, 0.87)',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '0px solid #dadde9',
  },
}));





function ButtonAppBar() {

  const history = useHistory();

  const coin_list_memes = [
    {ticker: "SAMO", image: "https://samoyedcoin.com/wp-content/uploads/2021/05/samoyed1.png"},
    {ticker: "WOOF", image: "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9nEqaUcb16sQ3Tn1psbkWqyhPdLmfHWjKGymREjsAgTE/logo.png"},
    {ticker: "CHEEMS", image: "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3FoUAsGDbvTD6YZ4wVKJgTB76onJUKz7GPEBNiR5b8wc/logo.png"},
    {ticker: "HIMA", image: "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/72hgmvS5zFxaFJfMizq6Gp4gjBqXjTPyX9GDP38krorQ/logo.png"},
    {ticker: "SOLAPE", image: "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GHvFFSZ9BctWsEc5nujR1MTmmJWY7tgQz2AXE6WVFtGN/logo.png"},
    {ticker: "MOLA", image: "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AMdnw9H5DFtQwZowVFr4kUgSXJzLokKSinvgGiUoLSps/logo.png"},
    {ticker: "CATO", image: "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5p2zjqCd1WJzAVgcEnjhb9zWDU7b9XVhFhx4usiyN7jB/logo.png"},
    {ticker: "SHIBA", image: "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Dhg9XnzJWzSQqH2aAnhPTEJHGQAkALDfD98MA499A7pa/logo.png"}
  ];

  const coin_list_real = [
    {ticker: "SRM", image: "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SRMuApVNdxXokk5GT7XD5cUUgXMBCoAz2LHeuAoKWRt/logo.png"},
    {ticker: "RAY", image: "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R/logo.png"},
    {ticker: "FIDA", image: "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EchesyfXePKdLtoiZSL8pBe8Myagyy8ZRqsACNCFGnvp/logo.svg"},
    {ticker: "MNGO", image: "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MangoCzJ36AjZyKwVj3VnYU4GTonjfVEnJmvvWaxLac/token.png"},
    {ticker: "ATLAS", image: "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ATLASXmbPQxBUYbxPsV97usA3fPQYEqzQBUHgiFCUsXx/logo.png"},
    {ticker: "POLIS", image: "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/poLisWXnNRwC6oBu1vHiuKQzFjGL4XDSu4g9qjz9qVk/logo.png"},
    {ticker: "ORCA", image: "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/orcaEKTdK7LKz57vaAYr9QeNsVEPfiu6QeMU1kektZE/logo.png"},
    {ticker: "AURY", image: "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AURYydfxJib1ZkTir1Jn1J9ECYUtjb6rKQVmtYaixWPP/logo.png"},
    {ticker: "STEP", image: "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/StepAscQoEioFxxWGnh2sLBDFp9d8rvKz2Yp39iDpyT/logo.png"},
    {ticker: "SBR", image: "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Saber2gLauYim4Mvftnrasomsv6NvAuncvMEZwcLpD1/logo.svg"},
    {ticker: "TULIP", image: "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/TuLipcqtGVXP9XR62wM8WWCm6a9vhLs7T1uoWBk6FDs/logo.svg"},
    {ticker: "COPE", image: "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8HGyAAB1yoM1ttS7pXjHMa3dukTFGQggnFFH3hJZgzQh/logo.png"}
  ];

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseHome = () => {
    setAnchorEl(null);
    history.push("/info");
  };


  const handleCloseDYN = (ticker) => {
    setAnchorEl(null);
    history.push(ticker.toUpperCase());
  };
  const memeCoins = coin_list_memes.map((item) =>
        <MenuItem key={item.ticker} onClick={handleCloseDYN.bind(this, item.ticker)}>
          <ListItemIcon>
            <img width="20px" height="20px" src={item.image}/>
          </ListItemIcon>
          <ListItemText>{item.ticker}</ListItemText>
        </MenuItem>
  );

  const realCoins = coin_list_real.map((item) =>
        <MenuItem key={item.ticker} onClick={handleCloseDYN.bind(this, item.ticker)}>
          <ListItemIcon>
            {/*<HomeIcon fontSize="small" />*/}
            <img width="20px" height="20px" src={item.image}/>
          </ListItemIcon>
          <ListItemText>{item.ticker}</ListItemText>
        </MenuItem>
  );

  const handleClose = () => {
    setAnchorEl(null);
  };


  const [toolOpen, setToolOpen] = React.useState(false);

  const handleToolClose = () => {
    setToolOpen(false);
  };

  const handleToolOpen = () => {
    setToolOpen(true);
  };

  /*
  useEffect(() => {
    setTimeout(function() { setToolOpen(true); }, 5000);
  }, []);
  */

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" style={{background: "rgba(255, 255, 255, 0.03)"}}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}

            id="basic-button"
            aria-controls="basic-menu"
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            <MenuIcon />
          </IconButton>
          
            <Stack direction="row" spacing={2}>
            {/*<Avatar alt="CoinChimp" sx={{ width: 32, height: 32 }} src={CoinChimpLogo} />*/}
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              <HtmlTooltip
        title={
                  <React.Fragment>
                    <Typography color="inherit">Notice</Typography>
                    <br/>{"You can now also reach us under"} <a href="https://coinchimp.app"><u><b>{'https://coinchimp.app'}</b></u></a> {"😀"} <br/><br/>{"The previous domain still works for some time though!"}
                  </React.Fragment>
                } open={toolOpen} onClose={handleToolClose} onOpen={handleToolOpen}
              >
                <span>CoinChimp</span>
              </HtmlTooltip>
            </Typography>
            </Stack>
          
          {/*<Button color="inherit">Login</Button>*/}
        </Toolbar>
      </AppBar>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleCloseHome}>
          <ListItemIcon>
            <HomeIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Info</ListItemText>
        </MenuItem>
        <Divider />
          {memeCoins}
        <Divider />
          {realCoins}
      </Menu>

    </Box>
  );
}




const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#cfd8dc",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 0,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));


function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <BorderLinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}






function App() {

  const coin_list_memes = [
    "SAMO",
    "WOOF",
    "CHEEMS",
    "HIMA",
    "SOLAPE",
    "MOLA",
    "CATO",
    "SHIBA"
  ];

  const coin_list_real = [
    "SRM",
    "RAY",
    "FIDA",
    "MNGO",
    "ATLAS",
    "POLIS",
    "ORCA",
    "AURY",
    "STEP",
    "SBR",
    "TULIP",
    "COPE"
  ];

  const memeCoins = coin_list_memes.map((ticker) =>
          <Route key={ticker} path={"/" + ticker}>
            <ButtonAppBar />
            <CoinStats coin={ticker} />
          </Route>
  );

  const realCoins = coin_list_real.map((ticker) =>
          <Route key={ticker} path={"/" + ticker}>
            <ButtonAppBar />
            <CoinStats coin={ticker} />
          </Route>
  );

  return (
    <Router>
      <div>
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route path={"/info"}>
            <ButtonAppBar />
            <Home />
          </Route>
          {memeCoins}
          {realCoins}
          <Route path={"/"}>
            <ButtonAppBar />
            <CoinStats coin="SAMO" />
          </Route>
        </Switch>

      </div>
    </Router>
  );
}



  // Function to return the count of elements
  // from the array which are greater than k
  function countGreater(arr, k) {

      var n = arr.length;
      var l = 0;
      var r = n - 1;
   
      // Stores the index of the left most element
      // from the array which is greater than k
      var leftGreater = n;
   
      // Finds number of elements greater than k
      while (l <= r) {
          var m = l + parseInt((r - l) / 2);
   
          // If mid element is greater than
          // k update leftGreater and r
          if (arr[m] > k) {
              leftGreater = m;
              r = m - 1;
          }
   
          // If mid element is less than
          // or equal to k update l
          else
              l = m + 1;
      }
   
      // Return the count of elements greater than k
      return (n - leftGreater);
  }


function RankForm(props) {
  const [holdings, setHoldings] = useState("");
  const arr = props.amounts;
  const decimal_multiplier = props.decimal_multiplier;
  const [myRank, setMyRank] = useState(0);
  
  const handleSubmit = (evt) => {
      evt.preventDefault();

      if (holdings != 0) {
        var my_rank = countGreater(arr, (holdings * decimal_multiplier)) + 1;
        setMyRank(my_rank)
      }
  }

  return (

    <Accordion style={{ borderRadius: "8px", marginBottom: "20px"}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Check your rank here</Typography>
        </AccordionSummary>
        <AccordionDetails>

          <form onSubmit={handleSubmit} className="holdingsForm">

            <Grid container spacing={1}>

              <Grid item xs={12}>
                  <TextField
                    size="small"
                    id="filled-holdings-input"
                    label={`Your ${props.coin_name} holdings`}
                    type="tel"
                    /*autoComplete="current-holdings"*/
                    variant="filled"
                    value={holdings}
                    onChange={e => setHoldings(e.target.value)}
                    fullWidth
                  />
              </Grid>

              <Grid item xs={12}>
                  <Button fullWidth variant="outlined" type="submit">Check my rank</Button>
              </Grid>

              {myRank != 0 && (
                <p style={{textAlign: "center", fontSize: "14px", fontFamily: "Roboto", width: "100%"}}>
                  You are rank <strong style={{color: "#27ae60"}}>{myRank}</strong> of {props.total} holders!<br/>
                  This puts you in the top <strong style={{color: "black"}}>{Math.ceil(myRank/props.total*1000)/10}%</strong> of holders!
                </p>
              )}
            
            </Grid>
          </form>

        </AccordionDetails>
      </Accordion>
  );
}



function CompareMarketCap(props) {

  const possible_price = (props.price * (props.shibMarketCap/props.samoMarketCap)).toFixed(2);
  const possible_price_doge = (props.price * (props.dogeMarketCap/props.samoMarketCap)).toFixed(2);

  return (

    <Accordion style={{ borderRadius: "8px", marginBottom: "20px"}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Compare market cap</Typography>
        </AccordionSummary>
        <AccordionDetails>

          <div className="holdingsForm">

            <Grid container spacing={1}>

              <Grid item xs={12}>

                  <div className="marketCapDiff" style={{marginBottom: "10px"}}>

                    <div className="mcHeader">{props.coin} with the market cap of SHIB <img width="16px" height="16px" src={props.shibImage}/></div>
                    <div className="mcPrice"><img width="24px" height="24px" src={props.coin_data.image}/> ${possible_price} <span>({(possible_price/props.price).toFixed(2)} <strong>x</strong>)</span></div>
                    <div className="credit">coinchimp.app</div>
                  </div>

                  <div className="marketCapDiff">

                    <div className="mcHeader">{props.coin} with the market cap of DOGE <img width="16px" height="16px" src={props.dogeImage}/></div>
                    <div className="mcPrice"><img width="24px" height="24px" src={props.coin_data.image}/> ${possible_price_doge} <span>({(possible_price_doge/props.price).toFixed(2)} <strong>x</strong>)</span></div>
                    <div className="credit">coinchimp.app</div>
                  </div>

              </Grid>
            
            </Grid>
          </div>

        </AccordionDetails>
      </Accordion>
  );
}




function WhaleAlerts(props) {
  const transactions = props.transactions;
  const dex_images = props.dex_images;
  const timeAgo = new TimeAgo('en-US');

  console.log(transactions);

  return (

    <Accordion style={{ borderRadius: "8px", marginBottom: "20px"}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Recent Whale <img height="20px" width="20px" src="https://cdn-icons-png.flaticon.com/512/1998/1998810.png"/> Transactions (${props.limit/1000}k+)</Typography>
        </AccordionSummary>
        <AccordionDetails>

            <p style={{textAlign: "left", fontSize: "12px"}}>The data is <strong>updated every 60 seconds</strong> automatically (no need to reload the site)!</p>


            <p>We know that there is some issue with retrieving the latest transaction data currently. This happened due to some changes of our data endpoints. We will try to fix it soon by building a new data endpoint! Currently, only if a whale transactions was among the latest 10 successful transactions, it will be shown here!</p>

            <TableContainer component={Paper} style={{background: '#ecf0f1'}}>

              <Table sx={{ minWidth: 200 }} aria-label="simple table" size="small">
                <TableHead>      

                  <TableRow>
                    <TableCell>Action</TableCell>

                    <TableCell>USD Value</TableCell>

                    <TableCell>Time</TableCell>

                    <TableCell align="center">Pool</TableCell>

                    <TableCell align="right">
                      Solscan Link
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {transactions.map((tx) => (
                    <TableRow
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {(tx.base.typeSwap == "from") ? <span style={{backgroundColor: "#e74c3c", color: "white", padding: "5px", borderRadius: "5px"}}>Sell</span> : <span style={{backgroundColor: "#27ae60", color: "white", padding: "5px", borderRadius: "5px"}}>Buy</span>}
                      </TableCell>

                      <TableCell component="th" scope="row">
                        {parseInt(tx.volumeUSD).toLocaleString()}
                      </TableCell>

                      <TableCell>{timeAgo.format(new Date(tx.blockUnixTime * 1000))}</TableCell>

                      <TableCell align="center" style={{fontSize: '10px', textAlign: "center"}}><img className="dexImage" width="16px" height="16px" src={dex_images[tx.source]}/><br/>{tx.base.symbol + "-" + tx.quote.symbol}</TableCell>

                      <TableCell component="th" scope="row" align="right">
                        <a style={{color: "#7f8c8d"}} target="_blank" href={"https://solscan.io/tx/" + tx.txHash}><HighlightAltIcon /></a>
                      </TableCell>

                    </TableRow>
                  ))}
                </TableBody>

              </Table>
            </TableContainer>

        </AccordionDetails>
      </Accordion>
  );
}



function Home(props) {

    return (
      <Container className="HomeContent" maxWidth="sm">
        <Paper style={{"padding": "10px", marginTop: "20px", marginBottom: "20px"}}>
        <p>Hey CoinChimp users!</p>
        <div style={{padding: "10px", border: "2px solid red", borderRadius: "4px", lineHeight: "1.8"}}><p>You can now also reach us via <strong><a href="https://coinchimp.app">coinchimp.app</a></strong> instead of <strong>cryptostats.pages.dev</strong>!</p></div>
        <p>Someone said that Twitter treats ".dev" domains as spammy. So we changed the domain :)</p>
        <p>The previous link still works though!</p>
        <p>The site experience is exactly the same.</p>
        <hr/>
        <p>This site is being built to serve the crypto community with useful statistical data that cannot be easily found on other sites.</p>
        <p>The Samoyed community inspired me to build this site in the first place.</p>
        <p>Being an investor in SAMO myself, I struggled to easily find the data that I was looking for. That's why I created this site :)</p>
        <p>We listen closely to users and their feedback in order to provide them with better data!</p>
        <p>Stay tuned for future updates!</p>
        </Paper>
        <Paper style={{"padding": "10px", marginTop: "20px"}}>
        <p><strong>Why the name CoinChimp?</strong></p>
        <p>The chimpanzee is widely known as the smartest animal!</p>
        <p>As our site tries to provide useful data and actionable insights to our users, the characteristics of chimps fit to our story!</p>
        </Paper>
      </Container>
    );

}




function ValueDifference(props) {
  const before = props.before;
  const now = props.now;
  const difference = now - before;
  if (difference > 0) {
    return <span>{now} <span style={{color: "green"}}>({['','+'][+(difference > 0)] + difference})</span></span>;
  } else if (difference == 0) {
    return <span>{now} <span style={{color: "rgba(0,0,0,0.5)"}}>({difference})</span></span>;
  }
  return <span>{now} <span style={{color: "red"}}>({difference})</span></span>;
}

function ValueDifferenceAlone(props) {
  const before = props.before;
  const now = props.now;
  const difference = now - before;
  if (difference > 0) {
    return <span style={{color: "green"}}>({['','+'][+(difference > 0)] + difference})</span>;
  } else if (difference == 0) {
    return <span style={{color: "rgba(0,0,0,0.5)"}}>({difference})</span>;
  }
  return <span style={{color: "red"}}>({difference})</span>;
}


function CoinStats(props) {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [total, setTotal] = useState(0);
  const [amounts, setAmounts] = useState([]);

  const [amountsReversed, setAmountsReversed] = useState([]); // From top to bottom holders
  const [amountsReversedCleaned, setAmountsReversedCleaned] = useState([]); // cleaned from burn wallets etc.

  const [data, setData] = useState([]);
  const [frequency, setFrequency] = useState([]);
  const [frequencyGroup, setFrequencyGroup] = useState([]);

  // Historical data a day ago
  const [totalDayAgo, setTotalDayAgo] = useState(0);
  const [amountsDayAgo, setAmountsDayAgo] = useState([]);
  const [dataDayAgo, setDataDayAgo] = useState([]);
  const [frequencyDayAgo, setFrequencyDayAgo] = useState([]);
  const [frequencyGroupDayAgo, setFrequencyGroupDayAgo] = useState([]);

  // Historical data 3 days ago
  const [total3DaysAgo, setTotal3DaysAgo] = useState(0);
  const [amounts3DaysAgo, setAmounts3DaysAgo] = useState([]);
  const [data3DaysAgo, setData3DaysAgo] = useState([]);
  const [frequency3DaysAgo, setFrequency3DaysAgo] = useState([]);
  const [frequencyGroup3DaysAgo, setFrequencyGroup3DaysAgo] = useState([]);

  // Historical data week ago
  const [totalWeekAgo, setTotalWeekAgo] = useState(0);
  const [amountsWeekAgo, setAmountsWeekAgo] = useState([]);
  const [dataWeekAgo, setDataWeekAgo] = useState([]);
  const [frequencyWeekAgo, setFrequencyWeekAgo] = useState([]);
  const [frequencyGroupWeekAgo, setFrequencyGroupWeekAgo] = useState([]);

  const [amountsGroup, setAmountsGroup] = useState([]);
  const [amountsTotal, setAmountsTotal] = useState(0);

  const [amountsGroupCleaned, setAmountsGroupCleaned] = useState([]); // cleaned from burn wallets etc.
  const [amountsTotalCleaned, setAmountsTotalCleaned] = useState(0); // cleaned from burn wallets etc.

  const [checked, setChecked] = useState(true);

  const [largestTransactions, setLargestTransactions] = useState([]);


  const handleChange = (event) => {
      setChecked(event.target.checked);
  };

  const [price, setPrice] = useState(0);

  const history = useHistory();
  const location = useLocation();


  // Tab Value Change
  const [tabValue, setTabValue] = React.useState(1);
  const handleTabChange = (event, newTabValue) => {
    setTabValue(newTabValue);
  };


  const coin = props.coin;
  const coin_data = {
    token_link: "",
    price_link: "",
    usd_account: "",
    coin_account: ""
  }


  // Set Market Cap
  const shib_api = "https://api.coingecko.com/api/v3/coins/shiba-inu?tickers=false&community_data=false&developer_data=false";
  const doge_api = "https://api.coingecko.com/api/v3/coins/dogecoin?tickers=false&community_data=false&developer_data=false";  
  const [shibMarketCap, setShibMarketCap] = useState(0);
  const [dogeMarketCap, setDogeMarketCap] = useState(0);
  const [shibImage, setShibImage] = useState("");
  const [dogeImage, setDogeImage] = useState("");
  const [samoMarketCap, setSamoMarketCap] = useState(0);

  /*

    const coin_list_memes = [
    "SAMO",
    "WOOF",
    "CHEEMS",
    "HIMA",
    "SOLAPE",
    "MOLA",
    "CATO"
  ];

  const coin_list_real = [
    "SRM",
    "RAY",
    "FIDA",
    "MNGO",
    "ATLAS",
    "POLIS",
    "ORCA",
    "AURY",
    "STEP",
    "SBR",
    "TULIP",
    "COPE"
  ];

  */

  if (coin == "CHEEMS") {
    coin_data.pool_name = "Raydium";
    coin_data.pool_pair = "CHEEMS-USDC";
    coin_data.supply_multiplier = 10;
    coin_data.image = "https://cheems.co/assets/img/logo.png";
    coin_data.token_link = "https://api.solscan.io/token/holders?token=3FoUAsGDbvTD6YZ4wVKJgTB76onJUKz7GPEBNiR5b8wc&offset=0&size=";
    coin_data.holders_data_day_ago = "";
    coin_data.token_accounts_link = "https://api.solscan.io/account/tokens?address=BCmUEpZm9t1Q7ZJpADJgi3qwaSiQyeWqvVPhy3MdmKj7&price=1";
    coin_data.usd_account = "HxWHVKhpw4GuDq8SGkUBbCWedzMW4EKe2FR3r1s5bwzZ";
    coin_data.coin_account = "5BR3cP9vD5QrgWJSMqjXHNfq1fpe9VheuTuUCdv3tDq1";
    coin_data.decimal_multiplier = 10000;
    coin_data.exclude_top_3_wallets = true;
  } else if (coin == "WOOF") {
    coin_data.pool_name = "Raydium";
    coin_data.pool_pair = "WOOF-USDC";
    coin_data.supply_multiplier = 100;
    coin_data.image = "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9nEqaUcb16sQ3Tn1psbkWqyhPdLmfHWjKGymREjsAgTE/logo.png";
    coin_data.token_link = "https://api.solscan.io/token/holders?token=9nEqaUcb16sQ3Tn1psbkWqyhPdLmfHWjKGymREjsAgTE&offset=0&size=";
    coin_data.holders_data_day_ago = "";
    coin_data.token_accounts_link = "https://api.solscan.io/account/tokens?address=BUwcHs7HSHMexNjrEuSaP3TY5xdqBo87384VmWMV9BQF&price=1";
    coin_data.usd_account = "3rfTMxRqmtoVvVsZXnvf2ifpFweeKSWxuFkYtyQnN9KG";
    coin_data.coin_account = "2VcGBzs54DWCVtAQsw8fx1VVdrxEvX7bJz3AD4j8EBHX";
    coin_data.decimal_multiplier = 1000000;
    coin_data.exclude_top_3_wallets = true;
    coin_data.amm_limit = 1000;
    coin_data.amm_links = [
      "https://api.solscan.io/amm/txs?address=HY6iq1dp7pHwstfmLFu8m2iEvRQtjX3N751jcN6KrQXU&type=all&tx_status=success&offset=0&limit=1000",
      "https://api.solscan.io/amm/txs?address=3HYhQC6ne6SAPVT5sPTKawRUxv9ZpYyLuk1ifrw8baov&type=all&tx_status=success&offset=0&limit=1000",
      "https://api.solscan.io/amm/txs?address=EZRHhpvAP4zEX1wZtTQcf6NP4FLWjs9c6tMRBqfrXgFD&type=all&tx_status=success&offset=0&limit=1000"
    ];
  } else if (coin == "HIMA") {
    coin_data.pool_name = "Raydium";
    coin_data.pool_pair = "HIMA-USDC";
    coin_data.supply_multiplier = 10;
    coin_data.image = "https://images.squarespace-cdn.com/content/v1/61843e273a2d4271ce3b1f37/3dc2d9b8-2eab-4ce3-913f-abe50bf74f3d/favicon.ico?format=100w";
    coin_data.token_link = "https://api.solscan.io/token/holders?token=72hgmvS5zFxaFJfMizq6Gp4gjBqXjTPyX9GDP38krorQ&offset=0&size=";
    coin_data.holders_data_day_ago = "";
    coin_data.token_accounts_link = "https://api.solscan.io/account/tokens?address=8US29p3Q6tFnixS7eJWp14EuQ3qoe4WpFSijfW5S8qGu&price=1";
    coin_data.usd_account = "6LMhwdVMo9xF9fgMNzmKFWzy2kkWzL9pHeYcxiDUN2yf";
    coin_data.coin_account = "BqrTCss5juV27E7ifk526axDwnzu3QfrcXBCHTVwV4NH";
    coin_data.decimal_multiplier = 1000000000;
    coin_data.exclude_top_3_wallets = true;
  } else if (coin == "SHIBA") {
    coin_data.pool_name = "Raydium";
    coin_data.pool_pair = "SHIBA-USDC";
    coin_data.supply_multiplier = 10;
    coin_data.image = "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Dhg9XnzJWzSQqH2aAnhPTEJHGQAkALDfD98MA499A7pa/logo.png";
    coin_data.token_link = "https://api.solscan.io/token/holders?token=Dhg9XnzJWzSQqH2aAnhPTEJHGQAkALDfD98MA499A7pa&offset=0&size=";
    coin_data.holders_data_day_ago = "";
    coin_data.token_accounts_link = "https://api.solscan.io/account/tokens?address=7Kf4rUuUPEQ8ABeuWSrHF7KZJmrAdcdx83wQnK4vvT8&price=1";
    coin_data.usd_account = "";
    coin_data.coin_account = "";
    coin_data.decimal_multiplier = 1000000000;
    coin_data.exclude_top_3_wallets = true;
  } else if (coin == "SOLAPE") {
    coin_data.pool_name = "Raydium";
    coin_data.pool_pair = "SOLAPE-USDC";
    coin_data.supply_multiplier = 1;
    coin_data.image = "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GHvFFSZ9BctWsEc5nujR1MTmmJWY7tgQz2AXE6WVFtGN/logo.png";
    coin_data.token_link = "https://api.solscan.io/token/holders?token=GHvFFSZ9BctWsEc5nujR1MTmmJWY7tgQz2AXE6WVFtGN&offset=0&size=";
    coin_data.holders_data_day_ago = "";
    coin_data.token_accounts_link = "";
    coin_data.usd_account = "";
    coin_data.coin_account = "";
    coin_data.decimal_multiplier = 1000000000;
    coin_data.exclude_top_3_wallets = true;
  } else if (coin == "MOLA") {
    coin_data.pool_name = "Raydium";
    coin_data.pool_pair = "MOLA-USDC";
    coin_data.supply_multiplier = 10;
    coin_data.image = "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AMdnw9H5DFtQwZowVFr4kUgSXJzLokKSinvgGiUoLSps/logo.png";
    coin_data.token_link = "https://api.solscan.io/token/holders?token=AMdnw9H5DFtQwZowVFr4kUgSXJzLokKSinvgGiUoLSps&offset=0&size=";
    coin_data.holders_data_day_ago = "";
    coin_data.token_accounts_link = "";
    coin_data.usd_account = "";
    coin_data.coin_account = "";
    coin_data.decimal_multiplier = 1000000000;
    coin_data.exclude_top_3_wallets = true;
  } else if (coin == "CATO") {
    coin_data.pool_name = "Raydium";
    coin_data.pool_pair = "CATO-USDC";
    coin_data.supply_multiplier = 1;
    coin_data.image = "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5p2zjqCd1WJzAVgcEnjhb9zWDU7b9XVhFhx4usiyN7jB/logo.png";
    coin_data.token_link = "https://api.solscan.io/token/holders?token=5p2zjqCd1WJzAVgcEnjhb9zWDU7b9XVhFhx4usiyN7jB&offset=0&size=";
    coin_data.holders_data_day_ago = "";
    coin_data.token_accounts_link = "";
    coin_data.usd_account = "";
    coin_data.coin_account = "";
    coin_data.decimal_multiplier = 1000000000;
    coin_data.exclude_top_3_wallets = true;
  } else if (coin == "SRM") {
    coin_data.pool_name = "Raydium";
    coin_data.pool_pair = "SRM-USDT";
    coin_data.supply_multiplier = 1;
    coin_data.image = "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SRMuApVNdxXokk5GT7XD5cUUgXMBCoAz2LHeuAoKWRt/logo.png";
    coin_data.token_link = "https://api.solscan.io/token/holders?token=SRMuApVNdxXokk5GT7XD5cUUgXMBCoAz2LHeuAoKWRt&offset=0&size=";
    coin_data.holders_data_day_ago = "";
    coin_data.token_accounts_link = "";
    coin_data.usd_account = "";
    coin_data.coin_account = "";
    coin_data.decimal_multiplier = 1000000;
    coin_data.exclude_top_3_wallets = false;
  } else if (coin == "RAY") {
    coin_data.pool_name = "Raydium";
    coin_data.pool_pair = "RAY-USDC";
    coin_data.supply_multiplier = 0.1;
    coin_data.image = "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R/logo.png";
    coin_data.token_link = "https://api.solscan.io/token/holders?token=4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R&offset=0&size=";
    coin_data.holders_data_day_ago = "";
    coin_data.token_accounts_link = "";
    coin_data.usd_account = "";
    coin_data.coin_account = "";
    coin_data.decimal_multiplier = 1000000;
    coin_data.exclude_top_3_wallets = false;
  } else if (coin == "FIDA") {
    coin_data.pool_name = "Orca";
    coin_data.pool_pair = "FIDA/SOL[aquafarm]";
    coin_data.supply_multiplier = 0.1;
    coin_data.image = "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EchesyfXePKdLtoiZSL8pBe8Myagyy8ZRqsACNCFGnvp/logo.svg";
    coin_data.token_link = "https://api.solscan.io/token/holders?token=EchesyfXePKdLtoiZSL8pBe8Myagyy8ZRqsACNCFGnvp&offset=0&size=";
    coin_data.holders_data_day_ago = "";
    coin_data.token_accounts_link = "";
    coin_data.usd_account = "";
    coin_data.coin_account = "";
    coin_data.decimal_multiplier = 1000000;
    coin_data.exclude_top_3_wallets = false;
  } else if (coin == "MNGO") {
    coin_data.pool_name = "Raydium";
    coin_data.pool_pair = "MNGO-USDC";
    coin_data.supply_multiplier = 1;
    coin_data.image = "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MangoCzJ36AjZyKwVj3VnYU4GTonjfVEnJmvvWaxLac/token.png";
    coin_data.token_link = "https://api.solscan.io/token/holders?token=MangoCzJ36AjZyKwVj3VnYU4GTonjfVEnJmvvWaxLac&offset=0&size=";
    coin_data.holders_data_day_ago = "";
    coin_data.token_accounts_link = "";
    coin_data.usd_account = "";
    coin_data.coin_account = "";
    coin_data.decimal_multiplier = 1000000;
    coin_data.exclude_top_3_wallets = false;
    coin_data.amm_limit = 1000;
    coin_data.amm_links = [
      "https://api.solscan.io/amm/txs?address=Hk9ZCvmqVT1FHNkWJMrtMkkVnH1WqssWPAvmio5Vs3se&type=all&tx_status=success&offset=0&limit=1000",
      "https://api.solscan.io/amm/txs?address=34tFULRrRwh4bMcBLPtJaNqqe5pVgGZACi5sR8Xz95KC&type=all&tx_status=success&offset=0&limit=1000"
    ];
  } else if (coin == "ATLAS") {
    coin_data.pool_name = "Raydium";
    coin_data.pool_pair = "ATLAS-USDC";
    coin_data.supply_multiplier = 1;
    coin_data.image = "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ATLASXmbPQxBUYbxPsV97usA3fPQYEqzQBUHgiFCUsXx/logo.png";
    coin_data.token_link = "https://api.solscan.io/token/holders?token=ATLASXmbPQxBUYbxPsV97usA3fPQYEqzQBUHgiFCUsXx&offset=0&size=";
    coin_data.holders_data_day_ago = "";
    coin_data.token_accounts_link = "";
    coin_data.usd_account = "";
    coin_data.coin_account = "";
    coin_data.decimal_multiplier = 100000000;
    coin_data.exclude_top_3_wallets = false;
    coin_data.amm_limit = 10000;
    coin_data.amm_links = [
      "https://api.solscan.io/amm/txs?address=3V5sjXj1mrWjjB1Xt6Xwp554QwHE5fppGSxbk4GzAtEW&type=all&tx_status=success&offset=0&limit=2000",
      "https://api.solscan.io/amm/txs?address=F73euqPynBwrgcZn3fNSEneSnYasDQohPM5aZazW9hp2&type=all&tx_status=success&offset=0&limit=2000",
      "https://api.solscan.io/amm/txs?address=2bnZ1edbvK3CK3LTNZ5jH9anvXYCmzPR4W2HQ6Ngsv5K&type=all&tx_status=success&offset=0&limit=2000"
    ];
  } else if (coin == "POLIS") {
    coin_data.pool_name = "Raydium";
    coin_data.pool_pair = "POLIS-USDC";
    coin_data.supply_multiplier = 0.1;
    coin_data.image = "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/poLisWXnNRwC6oBu1vHiuKQzFjGL4XDSu4g9qjz9qVk/logo.png";
    coin_data.token_link = "https://api.solscan.io/token/holders?token=poLisWXnNRwC6oBu1vHiuKQzFjGL4XDSu4g9qjz9qVk&offset=0&size=";
    coin_data.holders_data_day_ago = "";
    coin_data.token_accounts_link = "";
    coin_data.usd_account = "";
    coin_data.coin_account = "";
    coin_data.decimal_multiplier = 100000000;
    coin_data.exclude_top_3_wallets = false;
  } else if (coin == "ORCA") {
    coin_data.pool_name = "Raydium";
    coin_data.pool_pair = "ORCA-USDC";
    coin_data.supply_multiplier = 0.1;
    coin_data.image = "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/orcaEKTdK7LKz57vaAYr9QeNsVEPfiu6QeMU1kektZE/logo.png";
    coin_data.token_link = "https://api.solscan.io/token/holders?token=orcaEKTdK7LKz57vaAYr9QeNsVEPfiu6QeMU1kektZE&offset=0&size=";
    coin_data.holders_data_day_ago = "";
    coin_data.token_accounts_link = "";
    coin_data.usd_account = "";
    coin_data.coin_account = "";
    coin_data.decimal_multiplier = 1000000;
    coin_data.exclude_top_3_wallets = false;
    coin_data.amm_limit = 10000;
    coin_data.amm_links = [
      "https://api.solscan.io/amm/txs?address=2ZnVuidTHpi5WWKUwFXauYGhvdT9jRKYv5MDahtbwtYr&type=all&tx_status=success&offset=0&limit=1000",
      "https://api.solscan.io/amm/txs?address=2p7nYbtPBgtmY69NsE8DAW6szpRJn7tQvDnqvoEWQvjY&type=all&tx_status=success&offset=0&limit=1000",
      "https://api.solscan.io/amm/txs?address=49tTgthTYLMPEqozZNyEQifqkGYxHqqDie9YxVczS3iB&type=all&tx_status=success&offset=0&limit=1000",
      "https://api.solscan.io/amm/txs?address=7LfLiCnoLPefaCVuh6z92TK2tPZUa9bPjW7gHT4jqrec&type=all&tx_status=success&offset=0&limit=500",
      "https://api.solscan.io/amm/txs?address=C5yXRTp39qv5WZrfiqoqeyK6wvbqS97oBqbsDUqfZyu&type=all&tx_status=success&offset=0&limit=500"
    ];
    /*
    coin_data.amm_limit = 1000;
    coin_data.amm_links = [
      "https://api.solscan.io/amm/txs?address=&type=all&tx_status=success&offset=0&limit=1000",
      "https://api.solscan.io/amm/txs?address=&type=all&tx_status=success&offset=0&limit=1000",
      "https://api.solscan.io/amm/txs?address=&type=all&tx_status=success&offset=0&limit=1000",
      "https://api.solscan.io/amm/txs?address=&type=all&tx_status=success&offset=0&limit=500",
      "https://api.solscan.io/amm/txs?address=&type=all&tx_status=success&offset=0&limit=500"
    ];
    */
  } else if (coin == "AURY") {
    coin_data.pool_name = "Raydium";
    coin_data.pool_pair = "AURY-USDC";
    coin_data.supply_multiplier = 0.1;
    coin_data.image = "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AURYydfxJib1ZkTir1Jn1J9ECYUtjb6rKQVmtYaixWPP/logo.png";
    coin_data.token_link = "https://api.solscan.io/token/holders?token=AURYydfxJib1ZkTir1Jn1J9ECYUtjb6rKQVmtYaixWPP&offset=0&size=";
    coin_data.holders_data_day_ago = "";
    coin_data.token_accounts_link = "";
    coin_data.usd_account = "";
    coin_data.coin_account = "";
    coin_data.decimal_multiplier = 1000000000;
    coin_data.exclude_top_3_wallets = false;
  } else if (coin == "STEP") {
    coin_data.pool_name = "Raydium";
    coin_data.pool_pair = "STEP-USDC";
    coin_data.supply_multiplier = 1;
    coin_data.image = "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/StepAscQoEioFxxWGnh2sLBDFp9d8rvKz2Yp39iDpyT/logo.png";
    coin_data.token_link = "https://api.solscan.io/token/holders?token=StepAscQoEioFxxWGnh2sLBDFp9d8rvKz2Yp39iDpyT&offset=0&size=";
    coin_data.holders_data_day_ago = "";
    coin_data.token_accounts_link = "";
    coin_data.usd_account = "";
    coin_data.coin_account = "";
    coin_data.decimal_multiplier = 1000000000;
    coin_data.exclude_top_3_wallets = false;
  } else if (coin == "SBR") {
    coin_data.pool_name = "Raydium";
    coin_data.pool_pair = "SBR-USDC";
    coin_data.supply_multiplier = 1;
    coin_data.image = "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Saber2gLauYim4Mvftnrasomsv6NvAuncvMEZwcLpD1/logo.svg";
    coin_data.token_link = "https://api.solscan.io/token/holders?token=Saber2gLauYim4Mvftnrasomsv6NvAuncvMEZwcLpD1&offset=0&size=";
    coin_data.holders_data_day_ago = "";
    coin_data.token_accounts_link = "";
    coin_data.usd_account = "";
    coin_data.coin_account = "";
    coin_data.decimal_multiplier = 1000000;
    coin_data.exclude_top_3_wallets = false;
  } else if (coin == "TULIP") {
    coin_data.pool_name = "Raydium";
    coin_data.pool_pair = "TULIP-USDC";
    coin_data.supply_multiplier = 0.1;
    coin_data.image = "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/TuLipcqtGVXP9XR62wM8WWCm6a9vhLs7T1uoWBk6FDs/logo.svg";
    coin_data.token_link = "https://api.solscan.io/token/holders?token=TuLipcqtGVXP9XR62wM8WWCm6a9vhLs7T1uoWBk6FDs&offset=0&size=";
    coin_data.holders_data_day_ago = "";
    coin_data.token_accounts_link = "";
    coin_data.usd_account = "";
    coin_data.coin_account = "";
    coin_data.decimal_multiplier = 1000000;
    coin_data.exclude_top_3_wallets = false;
  } else if (coin == "COPE") {
    coin_data.pool_name = "Raydium";
    coin_data.pool_pair = "COPE-USDC";
    coin_data.supply_multiplier = 0.1;
    coin_data.image = "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8HGyAAB1yoM1ttS7pXjHMa3dukTFGQggnFFH3hJZgzQh/logo.png";
    coin_data.token_link = "https://api.solscan.io/token/holders?token=8HGyAAB1yoM1ttS7pXjHMa3dukTFGQggnFFH3hJZgzQh&offset=0&size=";
    coin_data.holders_data_day_ago = "";
    coin_data.token_accounts_link = "";
    coin_data.usd_account = "";
    coin_data.coin_account = "";
    coin_data.decimal_multiplier = 1000000;
    coin_data.exclude_top_3_wallets = false;
  } else {
    coin_data.pool_name = "Orca";
    coin_data.pool_pair = "SAMO/USDC";
    coin_data.supply_multiplier = 10;
    coin_data.image = "https://samoyedcoin.com/wp-content/uploads/2021/05/samoyed1.png";
    coin_data.token_link = "https://api.solscan.io/token/holders?token=7xKXtg2CW87d97TXJSDpbD5jBkheTqA83TZRuJosgAsU&offset=0&size=";
    coin_data.holders_data_day_ago = "https://raspy-disk-bb5e.coinchimp.workers.dev/api/SAMO/holders";
    coin_data.holders_data_three_days_ago = "https://raspy-disk-bb5e.coinchimp.workers.dev/api/SAMO/holders/three_days_ago";
    coin_data.holders_data_week_ago = "https://raspy-disk-bb5e.coinchimp.workers.dev/api/SAMO/holders/week_ago";
    coin_data.token_accounts_link = "https://api.solscan.io/account/tokens?address=AB4rTE2JiKFhnfynUQCovbW75CUxT9LxcJX2SDTbY9gy&price=1";
    coin_data.usd_account = "G7Gqjxk9EaJMeFfoFTSy9WfH8uurgQkbNQCREWAc56DZ";
    coin_data.coin_account = "7jwHW4Lw3nVaSJXskN5pUoKU6YB9RBVfZtGBp3VbR43U";
    coin_data.decimal_multiplier = 1000000000;
    coin_data.exclude_top_3_wallets = true;
    coin_data.amm_limit = 5000;
    coin_data.amm_links = [
      "https://api.solscan.io/amm/txs?address=Epvp7qMYAF21VVjacdB3VfKn6nnXQSF4rGYu8sD6Bkow&type=all&tx_status=success&offset=0&limit=5000",
      "https://api.solscan.io/amm/txs?address=DiAP9qmp5foN7fLTWfBWjo9KBS1jgvKUJLWi1ZhqKaja&type=all&tx_status=success&offset=0&limit=2500",
      "https://api.solscan.io/amm/txs?address=Hj45HZesMQD4ghdU7GuskiMyYBfxLnfibqKNgdaj8284&type=all&tx_status=success&offset=0&limit=2500",
      "https://api.solscan.io/amm/txs?address=EyDgEU9BdG7m6ZK4bYERxbN4NCJ129WzPtv23dBkfsLg&type=all&tx_status=success&offset=0&limit=2500",
      "https://api.solscan.io/amm/txs?address=7oYaghDwJ6ZbZwzdzcPqQtW6r4cojSLJDKB6U7tqAK1x&type=all&tx_status=success&offset=0&limit=2500"
    ];
  }

  const dex_images = {
    "orca": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/orcaEKTdK7LKz57vaAYr9QeNsVEPfiu6QeMU1kektZE/logo.png",
    "raydium": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R/logo.png"
  }

  const amount_numbers = {
    "10M+": 10000000,
    "5M+": 5000000,
    "2M+": 2000000,
    "1M+": 1000000,
    "500k+": 500000,
    "200k+": 200000,
    "100k+": 100000,
    "50k+": 50000,
    "20k+": 20000,
    "10k+": 10000,
    "5k+": 5000,
    "2k+": 2000,
    "1k+": 1000,
    "More than 0": 0
  };

  const amount_numbers_groups = {
    "10M+": 10000000,
    "5M-10M": 5000000,
    "2M-5M": 2000000,
    "1M-2M": 1000000,
    "500k-1M": 500000,
    "200k-500k": 200000,
    "100k-200k": 100000,
    "50k-100k": 50000,
    "20k-50k": 20000,
    "10k-20k": 10000,
    "5k-10k": 5000,
    "2k-5k": 2000,
    "1k-2k": 1000,
    "More than 0 but less than 1k": 0
  };

  const amount_numbers_100 = {
    "100M+": 100000000,
    "50M+": 50000000,
    "20M+": 20000000,
    "10M+": 10000000,
    "5M+": 5000000,
    "2M+": 2000000,
    "1M+": 1000000,
    "500k+": 500000,
    "200k+": 200000,
    "100k+": 100000,
    "50k+": 50000,
    "20k+": 20000,
    "10k+": 10000,
    "More than 0": 0
  };

  const amount_numbers_groups_100 = {
    "100M+": 100000000,
    "50M-100M": 50000000,
    "20M-50M": 20000000,
    "10M-20M": 10000000,
    "5M-10M": 5000000,
    "2M-5M": 2000000,
    "1M-2M": 1000000,
    "500k-1M": 500000,
    "200k-500k": 200000,
    "100k-200k": 100000,
    "50k-100k": 50000,
    "20k-50k": 20000,
    "10k-20k": 10000,
    "More than 0 but less than 10k": 0
  };

  const amount_numbers_1 = {
    "1M+": 1000000,
    "500k+": 500000,
    "200k+": 200000,
    "100k+": 100000,
    "50k+": 50000,
    "20k+": 20000,
    "10k+": 10000,
    "5k+": 5000,
    "2k+": 2000,
    "1k+": 1000,
    "500+": 500,
    "200+": 200,
    "100+": 100,
    "More than 0": 0
  };

  const amount_numbers_groups_1 = {
    "1M+": 1000000,
    "500k-1M": 500000,
    "200k-500k": 200000,
    "100k-200k": 100000,
    "50k-100k": 50000,
    "20k-50k": 20000,
    "10k-20k": 10000,
    "5k-10k": 5000,
    "2k-5k": 2000,
    "1k-2k": 1000,
    "500-1k": 500,
    "200-500": 200,
    "100-200": 100,
    "More than 0 but less than 100": 0
  };

  const amount_numbers_0_1 = {
    "100k+": 100000,
    "50k+": 50000,
    "20k+": 20000,
    "10k+": 10000,
    "5k+": 5000,
    "2k+": 2000,
    "1k+": 1000,
    "500+": 500,
    "200+": 200,
    "100+": 100,
    "50+": 50,
    "20+": 20,
    "10+": 10,
    "More than 0": 0
  };

  const amount_numbers_groups_0_1 = {
    "100k+": 100000,
    "50k-100k": 50000,
    "20k-50k": 20000,
    "10k-20k": 10000,
    "5k-10k": 5000,
    "2k-5k": 2000,
    "1k-2k": 1000,
    "500-1k": 500,
    "200-500": 200,
    "100-200": 100,
    "50-100": 50,
    "20-50": 20,
    "10-20": 10,
    "More than 0 but less than 10": 0
  };

  const amount_numbers_arr = Object.entries(amount_numbers);
  const amount_numbers_groups_arr = Object.entries(amount_numbers_groups);
  const amount_numbers_100_arr = Object.entries(amount_numbers_100);
  const amount_numbers_groups_100_arr = Object.entries(amount_numbers_groups_100);
  const amount_numbers_1_arr = Object.entries(amount_numbers_1);
  const amount_numbers_groups_1_arr = Object.entries(amount_numbers_groups_1);
  const amount_numbers_0_1_arr = Object.entries(amount_numbers_0_1);
  const amount_numbers_groups_0_1_arr = Object.entries(amount_numbers_groups_0_1);
  let final_amount_numbers = {};
  let final_amount_numbers_groups = {};
  let final_amount_numbers_arr = [];
  let final_amount_numbers_groups_arr = [];

  if (coin_data.supply_multiplier == 10) {
    final_amount_numbers = amount_numbers;
    final_amount_numbers_groups = amount_numbers_groups;

    final_amount_numbers_arr = amount_numbers_arr;
    final_amount_numbers_groups_arr = amount_numbers_groups_arr;
  } else if (coin_data.supply_multiplier == 100) {
    final_amount_numbers = amount_numbers_100;
    final_amount_numbers_groups = amount_numbers_groups_100;

    final_amount_numbers_arr = amount_numbers_100_arr;
    final_amount_numbers_groups_arr = amount_numbers_groups_100_arr;
  } else if (coin_data.supply_multiplier == 1) {
    final_amount_numbers = amount_numbers_1;
    final_amount_numbers_groups = amount_numbers_groups_1;

    final_amount_numbers_arr = amount_numbers_1_arr;
    final_amount_numbers_groups_arr = amount_numbers_groups_1_arr;
  } else if (coin_data.supply_multiplier == 0.1) {
    final_amount_numbers = amount_numbers_0_1;
    final_amount_numbers_groups = amount_numbers_groups_0_1;

    final_amount_numbers_arr = amount_numbers_0_1_arr;
    final_amount_numbers_groups_arr = amount_numbers_groups_0_1_arr;
  }


  function binarySearchForLeftRange(a, length, left_range) {
      if (a[length-1] < left_range) {
        return -1;
      }

      var low = 0;
      var high = length-1;

      while (low<=high)
      {
          var mid = Math.floor((low + high) / 2);

          if(a[mid] >= left_range) {
              high = mid-1;
          } else { //if(a[mid]<i)
              low = mid+1;
          }
      }

      return high+1;
  }

  function binarySearchForRightRange(a, length, right_range) {
    if (a[0] > right_range) {
        return -1;
    }

    var low = 0;
    var high = length-1;

    while (low<=high)
    {
        var mid = Math.floor((low + high) / 2);

        if(a[mid] > right_range) {
            high = mid-1;

        } else { //if(a[mid]<i)
            low = mid+1;
        }
    }

    return low-1;
  }

  function countFrequencyInRange(a_normal, left_range, right_range) {

    let a = a_normal;

    var length = a.length;
    var index_left = binarySearchForLeftRange(a, length, left_range);
    var index_right = binarySearchForRightRange(a, length, right_range);

    if (index_left==-1 || index_right==-1 || index_left>index_right) {
        var count = 0;
    } else {
        var count = index_right-index_left+1;
    }

    return count;
  }


  function getByTokenAccount(arr, value) {

    for (var i=0, iLen=arr.length; i<iLen; i++) {

      if (arr[i].tokenAccount == value) return arr[i];
    }
  }

  function getHighestValueTransactions(transactions, limit, oldest) {

    //volumeUSD: R.greaterThan (10000)
   let filters = {
      txStatus: "success",
      txType: "swap",
      volumeUSD: limit,
      blockUnixTime: oldest
   };

   let cleaned = transactions.filter((item) => {
       return Object.entries(filters).every(([filter, value]) => {
            if (filter == "volumeUSD") {
              return (item[filter] > value)
            }
            if (filter == "blockUnixTime") {
              return (item[filter] > value)
            }
            return item[filter] === value;
            //Here i am applying a bit more logic like 
            //return item[filter].includes(value) 
            //or filter with not exactly same key name like
            //return !isEmpty(item.key3)
       });
   });

    return cleaned;
  }

  function getByPairIdentifier(arr, value) {

    for (var i=0, iLen=arr.length; i<iLen; i++) {

      if (arr[i].name == value) return arr[i];
    }
  }


  function getAmountsSum(arr, from_key, to_key) {

    let sum = 0;

    for (var i=from_key, iLen=arr.length; i<=to_key; i++) {
      sum += arr[i];
    }

    return sum;

  }


  async function setMarketCap(price) {

        const response = await axios.get(shib_api);
        let market_cap = response["data"]["market_data"]["market_cap"]["usd"];

        setShibMarketCap(market_cap);
        setShibImage(response["data"]["image"]["small"]);

        const response_doge = await axios.get(doge_api);
        let market_cap_doge = response_doge["data"]["market_data"]["market_cap"]["usd"];

        setDogeMarketCap(market_cap_doge);
        setDogeImage(response_doge["data"]["image"]["small"]);

        if (coin == "SAMO") {
          const samo_supply = 2888446646;
          setSamoMarketCap(samo_supply * price);
        }
  }

  async function setTokenPrice() {

    if (/*coin == "CHEEMS" || coin == "WOOF" || coin == "HIMA"*/coin_data.pool_name == "Raydium") {
      //let post_params_usd = {"jsonrpc":"2.0", "id":1, "method":"getTokenAccountBalance", "params": [coin_data.usd_account]}
      //let post_params_coin = {"jsonrpc":"2.0", "id":1, "method":"getTokenAccountBalance", "params": [coin_data.coin_account]}

      //const response_usd = await axios.post('https://solana-api.projectserum.com', post_params_usd);
      //const response_coin = await axios.post('https://solana-api.projectserum.com', post_params_coin);

      const response = await axios.get("https://api.raydium.io/pairs");
      const response_data = response.data;

      const response_object = getByPairIdentifier(response_data, coin_data.pool_pair);

      let price = response_object["price"];

      setPrice(price.toFixed(5)); 

    } else {

      if (coin == "SAMO") {
        const response = await axios.get(coin_data.token_accounts_link);
        const response_data = response.data.data;
        const response_usd = getByTokenAccount(response_data, coin_data.usd_account);
        const response_coin = getByTokenAccount(response_data, coin_data.coin_account);


        let price = response_usd["tokenAmount"]["uiAmount"]/response_coin["tokenAmount"]["uiAmount"];

        setPrice(price.toFixed(5));

      } else if (coin == "FIDA") {


        const response = await axios.get("https://api.orca.so/pools");
        const response_data = response.data;

        const response_object = getByPairIdentifier(response_data, coin_data.pool_pair);

        console.log(response_object);

        const response_object_sol_usdc = getByPairIdentifier(response_data, "SOL/USDC[aquafarm]");

        let price = response_object["price"]*response_object_sol_usdc["price"];

        setPrice(price.toFixed(5));

      }

    }

  }


  async function getLatestWhaleTransactions() {

    if ((coin == "SAMO") || (coin == "WOOF") || (coin == "ORCA") || (coin == "MNGO") || (coin == "ATLAS")) {

      let the_amm_links = coin_data.amm_links;

      Promise.all(the_amm_links.map((link) =>
        fetch(link).then(resp => resp.json())
      )).then(
          (result) => {

            let results_cleaned = [];
            let results_latest_tx_unix_time = [];
            for(let i=0; i < result.length; i++) {
              let last_element = result[i].data.items[(result[i].data.items.length-1)];
              let last_element_time = last_element.blockUnixTime;
              results_latest_tx_unix_time.push(last_element_time);
              results_cleaned.push(result[i].data.items);
            }

            let oldestUnixTime = Math.max(...results_latest_tx_unix_time);

            console.log(results_cleaned);

            let flat_result = []
            results_cleaned.forEach(curSet=>{
                flat_result = [...flat_result,...curSet]
            })

            let limit = coin_data.amm_limit;

            let largest_transactions = getHighestValueTransactions(flat_result, limit, oldestUnixTime);

            const sorted = largest_transactions.sort((a, b) => {
               return b.blockUnixTime - a.blockUnixTime;
            });

            setLargestTransactions(sorted);

            console.log(largest_transactions);

          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            setIsLoaded(true);
            setError(error);
          }

      );

    }

  }



  useEffect(() => {
    setTokenPrice();
    setTabValue(1);

    const timeout = setInterval(() => setTokenPrice(), 10000);
    return () => clearInterval(timeout);
  }, [location]);

  useEffect(() => {
    setMarketCap(price);
  }, [price])

  // Note: the empty deps array [] means
  // this useEffect will run once
  // similar to componentDidMount()
  useEffect(() => {
    setIsLoaded(false);
    fetch(coin_data.token_link)
      .then(res => res.json())
      .then(
        (result) => {

          setIsLoaded(true);
          setTotal(result.data.total);
          setChecked(coin_data.exclude_top_3_wallets);
          let amounts = result.data.result.map(obj => {
             return obj.amount
          })
          setAmounts(amounts.reverse());
          setData(result.data.result);

          setFrequency({
            [final_amount_numbers_arr[0][0]]: countFrequencyInRange(amounts, final_amount_numbers_arr[0][1] * coin_data.decimal_multiplier, Math.min()),
            [final_amount_numbers_arr[1][0]]: countFrequencyInRange(amounts, final_amount_numbers_arr[1][1] * coin_data.decimal_multiplier, Math.min()),
            [final_amount_numbers_arr[2][0]]: countFrequencyInRange(amounts, final_amount_numbers_arr[2][1] * coin_data.decimal_multiplier, Math.min()),
            [final_amount_numbers_arr[3][0]]: countFrequencyInRange(amounts, final_amount_numbers_arr[3][1] * coin_data.decimal_multiplier, Math.min()),
            [final_amount_numbers_arr[4][0]]: countFrequencyInRange(amounts, final_amount_numbers_arr[4][1] * coin_data.decimal_multiplier, Math.min()),
            [final_amount_numbers_arr[5][0]]: countFrequencyInRange(amounts, final_amount_numbers_arr[5][1] * coin_data.decimal_multiplier, Math.min()),
            [final_amount_numbers_arr[6][0]]: countFrequencyInRange(amounts, final_amount_numbers_arr[6][1] * coin_data.decimal_multiplier, Math.min()),
            [final_amount_numbers_arr[7][0]]: countFrequencyInRange(amounts, final_amount_numbers_arr[7][1] * coin_data.decimal_multiplier, Math.min()),
            [final_amount_numbers_arr[8][0]]: countFrequencyInRange(amounts, final_amount_numbers_arr[8][1] * coin_data.decimal_multiplier, Math.min()),
            [final_amount_numbers_arr[9][0]]: countFrequencyInRange(amounts, final_amount_numbers_arr[9][1] * coin_data.decimal_multiplier, Math.min()),
            [final_amount_numbers_arr[10][0]]: countFrequencyInRange(amounts, final_amount_numbers_arr[10][1] * coin_data.decimal_multiplier, Math.min()),
            [final_amount_numbers_arr[11][0]]: countFrequencyInRange(amounts, final_amount_numbers_arr[11][1] * coin_data.decimal_multiplier, Math.min()),
            [final_amount_numbers_arr[12][0]]: countFrequencyInRange(amounts, final_amount_numbers_arr[12][1] * coin_data.decimal_multiplier, Math.min()),
            [final_amount_numbers_arr[13][0]]: result.data.total
          });

        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )


    getLatestWhaleTransactions();
    const timeout = setInterval(() => getLatestWhaleTransactions(), 60000);
    return () => clearInterval(timeout);

    // TURN OF TEMPORARILY

    /*
    if (coin == "SAMO") {

    fetch(coin_data.holders_data_day_ago)
      .then(res => res.json())
      .then(
        (result) => {

          // day ago

          setTotalDayAgo(result.data.total);

          let amounts_raw = result.data.result.map(obj => {
             return obj.amount
          })
          setAmountsDayAgo(amounts_raw.reverse());
          setDataDayAgo(result.data.result);

          setFrequencyDayAgo({
            [final_amount_numbers_arr[0][0]]: countFrequencyInRange(amounts_raw, final_amount_numbers_arr[0][1] * coin_data.decimal_multiplier, Math.min()),
            [final_amount_numbers_arr[1][0]]: countFrequencyInRange(amounts_raw, final_amount_numbers_arr[1][1] * coin_data.decimal_multiplier, Math.min()),
            [final_amount_numbers_arr[2][0]]: countFrequencyInRange(amounts_raw, final_amount_numbers_arr[2][1] * coin_data.decimal_multiplier, Math.min()),
            [final_amount_numbers_arr[3][0]]: countFrequencyInRange(amounts_raw, final_amount_numbers_arr[3][1] * coin_data.decimal_multiplier, Math.min()),
            [final_amount_numbers_arr[4][0]]: countFrequencyInRange(amounts_raw, final_amount_numbers_arr[4][1] * coin_data.decimal_multiplier, Math.min()),
            [final_amount_numbers_arr[5][0]]: countFrequencyInRange(amounts_raw, final_amount_numbers_arr[5][1] * coin_data.decimal_multiplier, Math.min()),
            [final_amount_numbers_arr[6][0]]: countFrequencyInRange(amounts_raw, final_amount_numbers_arr[6][1] * coin_data.decimal_multiplier, Math.min()),
            [final_amount_numbers_arr[7][0]]: countFrequencyInRange(amounts_raw, final_amount_numbers_arr[7][1] * coin_data.decimal_multiplier, Math.min()),
            [final_amount_numbers_arr[8][0]]: countFrequencyInRange(amounts_raw, final_amount_numbers_arr[8][1] * coin_data.decimal_multiplier, Math.min()),
            [final_amount_numbers_arr[9][0]]: countFrequencyInRange(amounts_raw, final_amount_numbers_arr[9][1] * coin_data.decimal_multiplier, Math.min()),
            [final_amount_numbers_arr[10][0]]: countFrequencyInRange(amounts_raw, final_amount_numbers_arr[10][1] * coin_data.decimal_multiplier, Math.min()),
            [final_amount_numbers_arr[11][0]]: countFrequencyInRange(amounts_raw, final_amount_numbers_arr[11][1] * coin_data.decimal_multiplier, Math.min()),
            [final_amount_numbers_arr[12][0]]: countFrequencyInRange(amounts_raw, final_amount_numbers_arr[12][1] * coin_data.decimal_multiplier, Math.min()),
            [final_amount_numbers_arr[13][0]]: result.data.total
          });
          */
          /*

          // 3 days ago

          setTotal3DaysAgo(result.data.total);

          let amounts_raw_2 = result.data.result.map(obj => {
             return obj.amount
          })
          setAmounts3DaysAgo(amounts_raw_2.reverse());
          setData3DaysAgo(result.data.result);

          setFrequency3DaysAgo({
            [final_amount_numbers_arr[0][0]]: countFrequencyInRange(amounts_raw_2, final_amount_numbers_arr[0][1] * coin_data.decimal_multiplier, Math.min()),
            [final_amount_numbers_arr[1][0]]: countFrequencyInRange(amounts_raw_2, final_amount_numbers_arr[1][1] * coin_data.decimal_multiplier, Math.min()),
            [final_amount_numbers_arr[2][0]]: countFrequencyInRange(amounts_raw_2, final_amount_numbers_arr[2][1] * coin_data.decimal_multiplier, Math.min()),
            [final_amount_numbers_arr[3][0]]: countFrequencyInRange(amounts_raw_2, final_amount_numbers_arr[3][1] * coin_data.decimal_multiplier, Math.min()),
            [final_amount_numbers_arr[4][0]]: countFrequencyInRange(amounts_raw_2, final_amount_numbers_arr[4][1] * coin_data.decimal_multiplier, Math.min()),
            [final_amount_numbers_arr[5][0]]: countFrequencyInRange(amounts_raw_2, final_amount_numbers_arr[5][1] * coin_data.decimal_multiplier, Math.min()),
            [final_amount_numbers_arr[6][0]]: countFrequencyInRange(amounts_raw_2, final_amount_numbers_arr[6][1] * coin_data.decimal_multiplier, Math.min()),
            [final_amount_numbers_arr[7][0]]: countFrequencyInRange(amounts_raw_2, final_amount_numbers_arr[7][1] * coin_data.decimal_multiplier, Math.min()),
            [final_amount_numbers_arr[8][0]]: countFrequencyInRange(amounts_raw_2, final_amount_numbers_arr[8][1] * coin_data.decimal_multiplier, Math.min()),
            [final_amount_numbers_arr[9][0]]: countFrequencyInRange(amounts_raw_2, final_amount_numbers_arr[9][1] * coin_data.decimal_multiplier, Math.min()),
            [final_amount_numbers_arr[10][0]]: countFrequencyInRange(amounts_raw_2, final_amount_numbers_arr[10][1] * coin_data.decimal_multiplier, Math.min()),
            [final_amount_numbers_arr[11][0]]: countFrequencyInRange(amounts_raw_2, final_amount_numbers_arr[11][1] * coin_data.decimal_multiplier, Math.min()),
            [final_amount_numbers_arr[12][0]]: countFrequencyInRange(amounts_raw_2, final_amount_numbers_arr[12][1] * coin_data.decimal_multiplier, Math.min()),
            [final_amount_numbers_arr[13][0]]: result.data.total
          });

          // Week ago

          setTotalWeekAgo(result.data.total);

          let amounts_raw_3 = result.data.result.map(obj => {
             return obj.amount
          })
          setAmountsWeekAgo(amounts_raw_3.reverse());
          setDataWeekAgo(result.data.result);

          setFrequencyWeekAgo({
            [final_amount_numbers_arr[0][0]]: countFrequencyInRange(amounts_raw_3, final_amount_numbers_arr[0][1] * coin_data.decimal_multiplier, Math.min()),
            [final_amount_numbers_arr[1][0]]: countFrequencyInRange(amounts_raw_3, final_amount_numbers_arr[1][1] * coin_data.decimal_multiplier, Math.min()),
            [final_amount_numbers_arr[2][0]]: countFrequencyInRange(amounts_raw_3, final_amount_numbers_arr[2][1] * coin_data.decimal_multiplier, Math.min()),
            [final_amount_numbers_arr[3][0]]: countFrequencyInRange(amounts_raw_3, final_amount_numbers_arr[3][1] * coin_data.decimal_multiplier, Math.min()),
            [final_amount_numbers_arr[4][0]]: countFrequencyInRange(amounts_raw_3, final_amount_numbers_arr[4][1] * coin_data.decimal_multiplier, Math.min()),
            [final_amount_numbers_arr[5][0]]: countFrequencyInRange(amounts_raw_3, final_amount_numbers_arr[5][1] * coin_data.decimal_multiplier, Math.min()),
            [final_amount_numbers_arr[6][0]]: countFrequencyInRange(amounts_raw_3, final_amount_numbers_arr[6][1] * coin_data.decimal_multiplier, Math.min()),
            [final_amount_numbers_arr[7][0]]: countFrequencyInRange(amounts_raw_3, final_amount_numbers_arr[7][1] * coin_data.decimal_multiplier, Math.min()),
            [final_amount_numbers_arr[8][0]]: countFrequencyInRange(amounts_raw_3, final_amount_numbers_arr[8][1] * coin_data.decimal_multiplier, Math.min()),
            [final_amount_numbers_arr[9][0]]: countFrequencyInRange(amounts_raw_3, final_amount_numbers_arr[9][1] * coin_data.decimal_multiplier, Math.min()),
            [final_amount_numbers_arr[10][0]]: countFrequencyInRange(amounts_raw_3, final_amount_numbers_arr[10][1] * coin_data.decimal_multiplier, Math.min()),
            [final_amount_numbers_arr[11][0]]: countFrequencyInRange(amounts_raw_3, final_amount_numbers_arr[11][1] * coin_data.decimal_multiplier, Math.min()),
            [final_amount_numbers_arr[12][0]]: countFrequencyInRange(amounts_raw_3, final_amount_numbers_arr[12][1] * coin_data.decimal_multiplier, Math.min()),
            [final_amount_numbers_arr[13][0]]: result.data.total
          });    

          */ /*     

        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          //setIsLoaded(true);
          setError(error);
        }
      );

      


    }*/

  }, [location])

  useEffect(() => {
      setFrequencyGroup({
        [final_amount_numbers_groups_arr[0][0]]: frequency[final_amount_numbers_arr[0][0]],
        [final_amount_numbers_groups_arr[1][0]]: frequency[final_amount_numbers_arr[1][0]] - frequency[final_amount_numbers_arr[0][0]],
        [final_amount_numbers_groups_arr[2][0]]: frequency[final_amount_numbers_arr[2][0]] - frequency[final_amount_numbers_arr[1][0]],
        [final_amount_numbers_groups_arr[3][0]]: frequency[final_amount_numbers_arr[3][0]] - frequency[final_amount_numbers_arr[2][0]],
        [final_amount_numbers_groups_arr[4][0]]: frequency[final_amount_numbers_arr[4][0]] - frequency[final_amount_numbers_arr[3][0]],
        [final_amount_numbers_groups_arr[5][0]]: frequency[final_amount_numbers_arr[5][0]] - frequency[final_amount_numbers_arr[4][0]],
        [final_amount_numbers_groups_arr[6][0]]: frequency[final_amount_numbers_arr[6][0]] - frequency[final_amount_numbers_arr[5][0]],
        [final_amount_numbers_groups_arr[7][0]]: frequency[final_amount_numbers_arr[7][0]] - frequency[final_amount_numbers_arr[6][0]],
        [final_amount_numbers_groups_arr[8][0]]: frequency[final_amount_numbers_arr[8][0]] - frequency[final_amount_numbers_arr[7][0]],
        [final_amount_numbers_groups_arr[9][0]]: frequency[final_amount_numbers_arr[9][0]] - frequency[final_amount_numbers_arr[8][0]],
        [final_amount_numbers_groups_arr[10][0]]: frequency[final_amount_numbers_arr[10][0]] - frequency[final_amount_numbers_arr[9][0]],
        [final_amount_numbers_groups_arr[11][0]]: frequency[final_amount_numbers_arr[11][0]] - frequency[final_amount_numbers_arr[10][0]],
        [final_amount_numbers_groups_arr[12][0]]: frequency[final_amount_numbers_arr[12][0]] - frequency[final_amount_numbers_arr[11][0]],
        [final_amount_numbers_groups_arr[13][0]]: frequency[final_amount_numbers_arr[13][0]] - frequency[final_amount_numbers_arr[12][0]]
      })
  }, [frequency])


  // day ago
  /*
  useEffect(() => {
    
    if (coin == "SAMO") {
      setFrequencyGroupDayAgo({
        [final_amount_numbers_groups_arr[0][0]]: frequencyDayAgo[final_amount_numbers_arr[0][0]],
        [final_amount_numbers_groups_arr[1][0]]: frequencyDayAgo[final_amount_numbers_arr[1][0]] - frequencyDayAgo[final_amount_numbers_arr[0][0]],
        [final_amount_numbers_groups_arr[2][0]]: frequencyDayAgo[final_amount_numbers_arr[2][0]] - frequencyDayAgo[final_amount_numbers_arr[1][0]],
        [final_amount_numbers_groups_arr[3][0]]: frequencyDayAgo[final_amount_numbers_arr[3][0]] - frequencyDayAgo[final_amount_numbers_arr[2][0]],
        [final_amount_numbers_groups_arr[4][0]]: frequencyDayAgo[final_amount_numbers_arr[4][0]] - frequencyDayAgo[final_amount_numbers_arr[3][0]],
        [final_amount_numbers_groups_arr[5][0]]: frequencyDayAgo[final_amount_numbers_arr[5][0]] - frequencyDayAgo[final_amount_numbers_arr[4][0]],
        [final_amount_numbers_groups_arr[6][0]]: frequencyDayAgo[final_amount_numbers_arr[6][0]] - frequencyDayAgo[final_amount_numbers_arr[5][0]],
        [final_amount_numbers_groups_arr[7][0]]: frequencyDayAgo[final_amount_numbers_arr[7][0]] - frequencyDayAgo[final_amount_numbers_arr[6][0]],
        [final_amount_numbers_groups_arr[8][0]]: frequencyDayAgo[final_amount_numbers_arr[8][0]] - frequencyDayAgo[final_amount_numbers_arr[7][0]],
        [final_amount_numbers_groups_arr[9][0]]: frequencyDayAgo[final_amount_numbers_arr[9][0]] - frequencyDayAgo[final_amount_numbers_arr[8][0]],
        [final_amount_numbers_groups_arr[10][0]]: frequencyDayAgo[final_amount_numbers_arr[10][0]] - frequencyDayAgo[final_amount_numbers_arr[9][0]],
        [final_amount_numbers_groups_arr[11][0]]: frequencyDayAgo[final_amount_numbers_arr[11][0]] - frequencyDayAgo[final_amount_numbers_arr[10][0]],
        [final_amount_numbers_groups_arr[12][0]]: frequencyDayAgo[final_amount_numbers_arr[12][0]] - frequencyDayAgo[final_amount_numbers_arr[11][0]],
        [final_amount_numbers_groups_arr[13][0]]: frequencyDayAgo[final_amount_numbers_arr[13][0]] - frequencyDayAgo[final_amount_numbers_arr[12][0]]
      })
    }

  }, [frequencyDayAgo])

  */

  /*
  // 3 days ago
  useEffect(() => {
    
    if (coin == "SAMO") {
      setFrequencyGroup3DaysAgo({
        [final_amount_numbers_groups_arr[0][0]]: frequency3DaysAgo[final_amount_numbers_arr[0][0]],
        [final_amount_numbers_groups_arr[1][0]]: frequency3DaysAgo[final_amount_numbers_arr[1][0]] - frequency3DaysAgo[final_amount_numbers_arr[0][0]],
        [final_amount_numbers_groups_arr[2][0]]: frequency3DaysAgo[final_amount_numbers_arr[2][0]] - frequency3DaysAgo[final_amount_numbers_arr[1][0]],
        [final_amount_numbers_groups_arr[3][0]]: frequency3DaysAgo[final_amount_numbers_arr[3][0]] - frequency3DaysAgo[final_amount_numbers_arr[2][0]],
        [final_amount_numbers_groups_arr[4][0]]: frequency3DaysAgo[final_amount_numbers_arr[4][0]] - frequency3DaysAgo[final_amount_numbers_arr[3][0]],
        [final_amount_numbers_groups_arr[5][0]]: frequency3DaysAgo[final_amount_numbers_arr[5][0]] - frequency3DaysAgo[final_amount_numbers_arr[4][0]],
        [final_amount_numbers_groups_arr[6][0]]: frequency3DaysAgo[final_amount_numbers_arr[6][0]] - frequency3DaysAgo[final_amount_numbers_arr[5][0]],
        [final_amount_numbers_groups_arr[7][0]]: frequency3DaysAgo[final_amount_numbers_arr[7][0]] - frequency3DaysAgo[final_amount_numbers_arr[6][0]],
        [final_amount_numbers_groups_arr[8][0]]: frequency3DaysAgo[final_amount_numbers_arr[8][0]] - frequency3DaysAgo[final_amount_numbers_arr[7][0]],
        [final_amount_numbers_groups_arr[9][0]]: frequency3DaysAgo[final_amount_numbers_arr[9][0]] - frequency3DaysAgo[final_amount_numbers_arr[8][0]],
        [final_amount_numbers_groups_arr[10][0]]: frequency3DaysAgo[final_amount_numbers_arr[10][0]] - frequency3DaysAgo[final_amount_numbers_arr[9][0]],
        [final_amount_numbers_groups_arr[11][0]]: frequency3DaysAgo[final_amount_numbers_arr[11][0]] - frequency3DaysAgo[final_amount_numbers_arr[10][0]],
        [final_amount_numbers_groups_arr[12][0]]: frequency3DaysAgo[final_amount_numbers_arr[12][0]] - frequency3DaysAgo[final_amount_numbers_arr[11][0]],
        [final_amount_numbers_groups_arr[13][0]]: frequency3DaysAgo[final_amount_numbers_arr[13][0]] - frequency3DaysAgo[final_amount_numbers_arr[12][0]]
      })
    }

  }, [frequency3DaysAgo])  

  // week ago

  useEffect(() => {
    
    if (coin == "SAMO") {
      setFrequencyGroupWeekAgo({
        [final_amount_numbers_groups_arr[0][0]]: frequencyWeekAgo[final_amount_numbers_arr[0][0]],
        [final_amount_numbers_groups_arr[1][0]]: frequencyWeekAgo[final_amount_numbers_arr[1][0]] - frequencyWeekAgo[final_amount_numbers_arr[0][0]],
        [final_amount_numbers_groups_arr[2][0]]: frequencyWeekAgo[final_amount_numbers_arr[2][0]] - frequencyWeekAgo[final_amount_numbers_arr[1][0]],
        [final_amount_numbers_groups_arr[3][0]]: frequencyWeekAgo[final_amount_numbers_arr[3][0]] - frequencyWeekAgo[final_amount_numbers_arr[2][0]],
        [final_amount_numbers_groups_arr[4][0]]: frequencyWeekAgo[final_amount_numbers_arr[4][0]] - frequencyWeekAgo[final_amount_numbers_arr[3][0]],
        [final_amount_numbers_groups_arr[5][0]]: frequencyWeekAgo[final_amount_numbers_arr[5][0]] - frequencyWeekAgo[final_amount_numbers_arr[4][0]],
        [final_amount_numbers_groups_arr[6][0]]: frequencyWeekAgo[final_amount_numbers_arr[6][0]] - frequencyWeekAgo[final_amount_numbers_arr[5][0]],
        [final_amount_numbers_groups_arr[7][0]]: frequencyWeekAgo[final_amount_numbers_arr[7][0]] - frequencyWeekAgo[final_amount_numbers_arr[6][0]],
        [final_amount_numbers_groups_arr[8][0]]: frequencyWeekAgo[final_amount_numbers_arr[8][0]] - frequencyWeekAgo[final_amount_numbers_arr[7][0]],
        [final_amount_numbers_groups_arr[9][0]]: frequencyWeekAgo[final_amount_numbers_arr[9][0]] - frequencyWeekAgo[final_amount_numbers_arr[8][0]],
        [final_amount_numbers_groups_arr[10][0]]: frequencyWeekAgo[final_amount_numbers_arr[10][0]] - frequencyWeekAgo[final_amount_numbers_arr[9][0]],
        [final_amount_numbers_groups_arr[11][0]]: frequencyWeekAgo[final_amount_numbers_arr[11][0]] - frequencyWeekAgo[final_amount_numbers_arr[10][0]],
        [final_amount_numbers_groups_arr[12][0]]: frequencyWeekAgo[final_amount_numbers_arr[12][0]] - frequencyWeekAgo[final_amount_numbers_arr[11][0]],
        [final_amount_numbers_groups_arr[13][0]]: frequencyWeekAgo[final_amount_numbers_arr[13][0]] - frequencyWeekAgo[final_amount_numbers_arr[12][0]]
      })
    }

  }, [frequencyWeekAgo])

  */


  useEffect(() => { // Reversing the amounts so that the first ones are the largest ones

      let amountsReversedRaw = [...amounts].reverse();
      setAmountsReversed(amountsReversedRaw);

  }, [frequency])
      
  
  useEffect(() => { // Setting the cleaned amounts (free of burn wallets etc.)

      let amountsReversedRaw = [...amountsReversed]; // cloning array

      amountsReversedRaw[0] = 0;
      amountsReversedRaw[1] = 0;
      amountsReversedRaw[2] = 0;

      setAmountsReversedCleaned(amountsReversedRaw);

  }, [amountsReversed])


  useEffect(() => { // Calculating the amounts sum of the non-cleaned and cleaned amounts (by group)

      setAmountsGroup({
        [final_amount_numbers_groups_arr[0][0]]: getAmountsSum(amountsReversed, 0, frequency[final_amount_numbers_arr[0][0]] - 1),
        [final_amount_numbers_groups_arr[1][0]]: getAmountsSum(amountsReversed, frequency[final_amount_numbers_arr[0][0]], frequency[final_amount_numbers_arr[1][0]] - 1),
        [final_amount_numbers_groups_arr[2][0]]: getAmountsSum(amountsReversed, frequency[final_amount_numbers_arr[1][0]], frequency[final_amount_numbers_arr[2][0]] - 1),
        [final_amount_numbers_groups_arr[3][0]]: getAmountsSum(amountsReversed, frequency[final_amount_numbers_arr[2][0]], frequency[final_amount_numbers_arr[3][0]] - 1),
        [final_amount_numbers_groups_arr[4][0]]: getAmountsSum(amountsReversed, frequency[final_amount_numbers_arr[3][0]], frequency[final_amount_numbers_arr[4][0]] - 1),
        [final_amount_numbers_groups_arr[5][0]]: getAmountsSum(amountsReversed, frequency[final_amount_numbers_arr[4][0]], frequency[final_amount_numbers_arr[5][0]] - 1),
        [final_amount_numbers_groups_arr[6][0]]: getAmountsSum(amountsReversed, frequency[final_amount_numbers_arr[5][0]], frequency[final_amount_numbers_arr[6][0]] - 1),
        [final_amount_numbers_groups_arr[7][0]]: getAmountsSum(amountsReversed, frequency[final_amount_numbers_arr[6][0]], frequency[final_amount_numbers_arr[7][0]] - 1),
        [final_amount_numbers_groups_arr[8][0]]: getAmountsSum(amountsReversed, frequency[final_amount_numbers_arr[7][0]], frequency[final_amount_numbers_arr[8][0]] - 1),
        [final_amount_numbers_groups_arr[9][0]]: getAmountsSum(amountsReversed, frequency[final_amount_numbers_arr[8][0]], frequency[final_amount_numbers_arr[9][0]] - 1),
        [final_amount_numbers_groups_arr[10][0]]: getAmountsSum(amountsReversed, frequency[final_amount_numbers_arr[9][0]], frequency[final_amount_numbers_arr[10][0]] - 1),
        [final_amount_numbers_groups_arr[11][0]]: getAmountsSum(amountsReversed, frequency[final_amount_numbers_arr[10][0]], frequency[final_amount_numbers_arr[11][0]] - 1),
        [final_amount_numbers_groups_arr[12][0]]: getAmountsSum(amountsReversed, frequency[final_amount_numbers_arr[11][0]], frequency[final_amount_numbers_arr[12][0]] - 1),
        [final_amount_numbers_groups_arr[13][0]]: getAmountsSum(amountsReversed, frequency[final_amount_numbers_arr[12][0]], frequency[final_amount_numbers_arr[13][0]] - 1),
      });

      setAmountsGroupCleaned({
        [final_amount_numbers_groups_arr[0][0]]: getAmountsSum(amountsReversedCleaned, 0, frequency[final_amount_numbers_arr[0][0]] - 1),
        [final_amount_numbers_groups_arr[1][0]]: getAmountsSum(amountsReversedCleaned, frequency[final_amount_numbers_arr[0][0]], frequency[final_amount_numbers_arr[1][0]] - 1),
        [final_amount_numbers_groups_arr[2][0]]: getAmountsSum(amountsReversedCleaned, frequency[final_amount_numbers_arr[1][0]], frequency[final_amount_numbers_arr[2][0]] - 1),
        [final_amount_numbers_groups_arr[3][0]]: getAmountsSum(amountsReversedCleaned, frequency[final_amount_numbers_arr[2][0]], frequency[final_amount_numbers_arr[3][0]] - 1),
        [final_amount_numbers_groups_arr[4][0]]: getAmountsSum(amountsReversedCleaned, frequency[final_amount_numbers_arr[3][0]], frequency[final_amount_numbers_arr[4][0]] - 1),
        [final_amount_numbers_groups_arr[5][0]]: getAmountsSum(amountsReversedCleaned, frequency[final_amount_numbers_arr[4][0]], frequency[final_amount_numbers_arr[5][0]] - 1),
        [final_amount_numbers_groups_arr[6][0]]: getAmountsSum(amountsReversedCleaned, frequency[final_amount_numbers_arr[5][0]], frequency[final_amount_numbers_arr[6][0]] - 1),
        [final_amount_numbers_groups_arr[7][0]]: getAmountsSum(amountsReversedCleaned, frequency[final_amount_numbers_arr[6][0]], frequency[final_amount_numbers_arr[7][0]] - 1),
        [final_amount_numbers_groups_arr[8][0]]: getAmountsSum(amountsReversedCleaned, frequency[final_amount_numbers_arr[7][0]], frequency[final_amount_numbers_arr[8][0]] - 1),
        [final_amount_numbers_groups_arr[9][0]]: getAmountsSum(amountsReversedCleaned, frequency[final_amount_numbers_arr[8][0]], frequency[final_amount_numbers_arr[9][0]] - 1),
        [final_amount_numbers_groups_arr[10][0]]: getAmountsSum(amountsReversedCleaned, frequency[final_amount_numbers_arr[9][0]], frequency[final_amount_numbers_arr[10][0]] - 1),
        [final_amount_numbers_groups_arr[11][0]]: getAmountsSum(amountsReversedCleaned, frequency[final_amount_numbers_arr[10][0]], frequency[final_amount_numbers_arr[11][0]] - 1),
        [final_amount_numbers_groups_arr[12][0]]: getAmountsSum(amountsReversedCleaned, frequency[final_amount_numbers_arr[11][0]], frequency[final_amount_numbers_arr[12][0]] - 1),
        [final_amount_numbers_groups_arr[13][0]]: getAmountsSum(amountsReversedCleaned, frequency[final_amount_numbers_arr[12][0]], frequency[final_amount_numbers_arr[13][0]] - 1),
      });

      setAmountsTotal(getAmountsSum(amountsReversed, 0, frequency[final_amount_numbers_arr[13][0]] - 1));

      setAmountsTotalCleaned(getAmountsSum(amountsReversedCleaned, 0, frequency[final_amount_numbers_arr[13][0]] - 1));

  }, [amountsReversedCleaned])




  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <Container className="CoinStats" maxWidth="sm">
             <br/><br/><br/><br/><br/><br/>
             <LinearProgress />
             <p style={{color: "white"}}>Loading {coin} data from blockchain ...</p>
           </Container>
  } else {
    return (
      <Container className="CoinStats" maxWidth="sm">
      <Avatar className="coinAvatar" sx={{ width: 66, height: 66 }} alt={coin} src={coin_data.image} />
      <h3 style={{"padding": "10px", "textAlign": "center", "color": "white"}}>
        Total {coin} Holders: {total}
      </h3>
      {(coin.length > 1) ?
      <p style={{"padding": "10px", "textAlign": "center", "color": "white", "fontSize": "14px", lineHeight: "1.8"}}>
        Live {coin} price based on {coin_data.pool_name} pool:<br/><span style={{"borderRadius": "4px", "padding": "4px", "background": "#27ae60", "color": "white", "marginLeft": "10px"}}>{price} USD</span>
      </p> : null }

      {(coin.length > 1) ?
      <p style={{"textAlign": "center", "color": "white", "fontSize": "12px"}}>The price is updated in the background every 10 seconds (no need to refresh the site)</p>
      : null }

        <RankForm coin_name={coin} amounts={amounts} total={total} decimal_multiplier={coin_data.decimal_multiplier}/>

        {(coin == "SAMO") &&
          <CompareMarketCap coin_data={coin_data} shibImage={shibImage} dogeImage={dogeImage} shibMarketCap={shibMarketCap} dogeMarketCap={dogeMarketCap} samoMarketCap={samoMarketCap} coin={coin} price={price}/>
        }

        {((coin == "SAMO") || (coin == "WOOF") || (coin == "ORCA") || (coin == "MNGO") || (coin == "ATLAS")) &&
          <WhaleAlerts transactions={largestTransactions} dex_images={dex_images} limit={coin_data.amm_limit}/>
        }

        


        {((coin.length > 1) && (tabValue == 2)) ? <div style={{color: "white"}}><span>Exclude top 3 wallets</span><Switch2 color="secondary" checked={checked} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }}/><br/><div style={{fontSize: "10px", paddingBottom: "10px"}}>Those top 3 wallets may include burn wallets & to-be-burned wallets & locked tokens.<br/>However, there is no guarantee or warranty for that statement!<br/>Keep in mind that wallets outside the top three may or may not fall in such categories as well.<br/>Always do your own research!</div></div> : null}

        <TableContainer component={Paper} style={{background: '#ecf0f1'}}>

          <Box sx={{ width: '100%' }}>
              
                <Tabs value={tabValue} onChange={handleTabChange} centered>
                  <Tab label="Cumulative" />
                  <Tab label="Groups" />
                 {/*(coin == "SAMO") ? null : <Tab label="Total Value %" />*/}
                 <Tab label="Total Value %" />
                </Tabs>
          </Box>

          <Table sx={{ minWidth: 200 }} aria-label="simple table">
            <TableHead>
              {/*
              <TableRow style={{background: "#131A36"}}>
                <TableCell></TableCell>
                {((coin.length > 1) && (tabValue != 2)) ? <TableCell></TableCell> : null}
                <TableCell style={{color: "white", fontSize: "12px", lineHeight: "1", padding: "8px 16px"}} align="right">
                  <span>Cumulative</span><Switch2 color="secondary" checked={checked} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }}/>
                </TableCell>
              </TableRow>
              */}            

              <TableRow>
                <TableCell>Amount of {coin} held</TableCell>

                {((coin.length > 1) && (tabValue != 2)) ? <TableCell>Live Value in USD</TableCell> : null}
                {((coin.length > 1) && (tabValue == 2)) ? <TableCell>Total % of coins owned by group</TableCell> : null}

                {((coin.length > 1) && (tabValue != 2)) ?
                <TableCell align="right">
                  # Holders {(tabValue == 0) ? "[Cumulative]" : "[Group]"} <br/><span style={{fontSize: "10px"}}></span>
                </TableCell>
                : null }
                {/* ADDED FOR UPDATE START */}
                {((coin == "SAMOO") && (tabValue != 2)) ?
                <TableCell align="right">
                  24h
                </TableCell>
                : null }
                {/* ADDED FOR UPDATE END */}

              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries((tabValue == 0) ? frequency : frequencyGroup).map(([key, value]) => (
                <TableRow
                  key={key}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {key}
                  </TableCell>
                  {((coin.length > 1) && (tabValue != 2)) ? <TableCell>{parseFloat((((tabValue == 0) ? final_amount_numbers : final_amount_numbers_groups)[key]*price).toFixed(0)).toLocaleString('en-US')}+</TableCell> : null }
                  {((coin.length > 1) && (tabValue == 2)) ? <TableCell align="right"><LinearProgressWithLabel value={(!checked) ? (amountsGroup[key]/amountsTotal*100) : (amountsGroupCleaned[key]/amountsTotalCleaned*100)} /></TableCell> : null}

                  {((coin == "SAMO") && (tabValue != 2)) ? 
                    <TableCell align="right">{/*(tabValue == 0) ? 
                      <ValueDifference before={frequencyDayAgo[key]} now={value}/> : 
                      <ValueDifference before={frequencyGroupDayAgo[key]} now={value}/>*/ value}
                    </TableCell> : 
                  null}
                  {/* ADDED FOR UPDATE START */}
                  {((coin == "SAMOO") && (tabValue != 2)) ? 
                    <TableCell align="right">{(tabValue == 0) ? 
                      <ValueDifferenceAlone before={frequencyDayAgo[key]} now={value}/> : 
                      <ValueDifferenceAlone before={frequencyGroupDayAgo[key]} now={value}/>/*conditional E*/}
                    </TableCell> : 
                  null}
                  {/* ADDED FOR UPDATE END */}

                  {((coin != "SAMO") && (tabValue != 2)) ? 
                    <TableCell align="right">{value}</TableCell> : 
                  null}

                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {!(tabValue == 1) ? <p style={{color: "white", fontSize: "12px", lineHeight: "1.5", backgroundColor: "rgba(255,255,255,0.1)", padding: "5px", borderRadius: "4px"}}>When you are in group view, keep in mind that the second amount listed is not included. For example <mark>2M-5M includes 2M but not 5M</mark>. Holders with exactly 5M of holdings belong to the group 5M-10M.</p> : null}

        <p style={{"textAlign": "center", "color": "white", "fontSize": "12px"}}>Keep in mind that if you look at a row of holders that hold more than a specific amount of tokens (for example 2M+), then the number of holders includes everyone with at least that amount (so in our example the ones with 5M+ and 10M+ are included).</p>
        <p style={{"textAlign": "center", "color": "white", "fontSize": "12px"}}>This site is not investment advice! Even though we try our best, we do not guarantee correctness of the data provided! Always do your own research before making an investment decision!</p>
        {(coin == "SAMO") && <p style={{"fontSize": "10px", "color": "white", opacity: "0.8"}}>To compare market caps, we use data from the coingecko.com API service.</p>}

      </Container>
    );
  }
}

export default App;
